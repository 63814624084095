<template>
    <div id="secure">
        <h1>Meine Daten</h1>

        <table v-if="userData">
            <tr>
                <th>Vorname</th>
                <td>{{ userData.firstName }}</td>
            </tr>
            <tr>
                <th>Nachname</th>
                <td>{{ userData.lastName }}</td>
            </tr>
            <tr>
                <th>E-Mail</th>
                <td>{{ userData.email }}</td>
            </tr>
            <tr>
                <th>Registrierung</th>
                <td>{{ dateFormat(userData.created, "myDate") }}</td>
            </tr>
            <tr>
                <th>Letzte Änderung</th>
                <td>{{ dateFormat(userData.updated, "myDate") }}</td>
            </tr>
            <tr>
                <th>Adresse</th>
                <td>
                    {{ userData.address.street }}
                    <br>
                    {{ userData.address.postCode }}
                    {{ userData.address.city }}
                    <br>
                    {{ userData.address.country }}
                </td>
            </tr>
        </table>
        <br>

        <md-button class="md-raised" to="/user/my_data/edit/">Bearbeiten</md-button>
        <!--<md-button class="md-raised md-accent" v-on:click="reqDelete()">Löschen</md-button>-->

        <md-dialog :md-active.sync="dialDelete">
            <md-dialog-title>
                Sicher löschen?
            </md-dialog-title>
            <md-dialog-content>
                <!-- TODO Account löschen implementieren -->
                <!-- TODO Passwortabfrage? -->
                Alle Daten zu Ihrem Konto werden auf dem Sever gelöscht.
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised" v-on:click="dialDelete = false">Doch nicht!</md-button>
                <md-button class="md-accent md-raised" onclick="alert('TODO: Löschen')">Löschen!</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import {dateFormat} from "../helpers/dates";

export default {
    name: 'User_Data',
    props: {},
    data: () => ({
        dialDelete: false,
        dateFormat
    }),
    computed: {
        userData: function () {
            return this.$store.state.user
        }
    },
    components: {},
    methods: {
        reqDelete() {
            this.dialDelete = true;
        },
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

table {
    display: inline-block;
    border-collapse: collapse;
}

table > tr > th {
    font-size: large;
    padding: 10px;
}

table > tr > td {
    padding: 10px;
}

table > tr:nth-child(odd) {
    background: #EEE;
}

table > tr:nth-child(even) {
    background: #FFF;
}
</style>
